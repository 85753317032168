<template>
  <div>
    <data-table
      :items="reports"
      :headers="headers"
      :custom-columns="['item.project', 'item.members']"
    >
      <template v-slot:custom-render-item.project="{ item }">
        <div class="d-flex align-center">
          <v-icon
            x-small
            class="dot-icon mr-2"
            :class="`success-dot`"
          >
            fiber_manual_record
          </v-icon>
          {{ item.project }}
        </div>
      </template>
      <template v-slot:custom-render-item.members="{ item }">
        <div class="d-flex align-center">
          <img
            v-for="(src, index) of item.members.slice(0, item.count)"
            :key="index"
            class="member-img"
            :src="src.img"
            alt=""
          >
          <div
            class="member-img more"
            @click="showAllMembers(item.id)"
          >
            +{{ item.members.length - 3 }}
          </div>
        </div>
      </template>
    </data-table>
    <data-table-list v-if="false" />
  </div>
</template>

<script>
import { REPORT_DAILY_HEADERS } from '@/views/Report/constants';
import DataTable from '@/components/collections/DataTable/DataTable';
import DataTableList from '@/components/collections/DataTable/DataTableList';

export default {
  name: 'Daily',
  components: { DataTableList, DataTable },
  data() {
    return {
      reports: [
        {
          id: '1',
          project: 'Test Project',
          members: [
            /* eslint-disable global-require */
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            // eslint-enable global-require
          ],
          activity: '77%',
          time: '24:44:04',
          count: 3,
        },
        {
          id: '2',
          project: 'Test Project',
          members: [
            /* eslint-disable global-require */
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            // eslint-enable global-require
          ],
          activity: '77%',
          time: '24:44:04',
          count: 3,
        },
        {
          id: '3',
          project: 'Test Project',
          members: [
            /* eslint-disable global-require */
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            { img: require('@/static/account.png') },
            // eslint-enable global-require
          ],
          activity: '77%',
          time: '24:44:04',
          count: 3,
        },
      ],
      headers: REPORT_DAILY_HEADERS,
    };
  },
  methods: {
    showAllMembers(val) {
      // TODO set count: Number; for show more
      this.$set(this.reports[val - 1], 'count', this.reports[val - 1].members.length);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .member-img {
    width: 30px;
    height: 30px;
    border: 2px solid $white;
    border-radius: 11px;
    &:not(:first-child) {
      margin-left: -10px;
    }
    &.more {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      color: $white;
      font-size: $type-xs;
      cursor: pointer;
    }
  }
</style>
