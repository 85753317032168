<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 4H2C1.44772 4 1 4.44772 1 5V15C1 15.5523 1.44772
      16 2 16H12C12.5523 16 13 15.5523 13 15V10"
      stroke="#656375"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M15 2L7 10M15 2H11M15 2V6"
      stroke="#656375"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Action',
};
</script>

<style scoped>

</style>
