<template>
  <div>
    <activity-time-avg
      class="activity"
      date="10.10.10.10"
      activity-time="activityTime"
      activity-percent="activityPercent"
    />
    <data-table
      :items="reports"
      :headers="headers"
      :custom-columns="['item.project']"
    >
      <template v-slot:custom-render-item.project="{ item }">
        <div class="d-flex align-center">
          <v-icon
            x-small
            class="dot-icon mr-2"
            :class="`success-dot`"
          >
            fiber_manual_record
          </v-icon>
          {{ item.project }}
        </div>
      </template>
      <template v-slot:actions-menu="{ item }">
        <data-table-actions>
          <data-table-action-item
            @click="foo(item)"
          >
            <action />
          </data-table-action-item>
          <data-table-action-item
            icon="delete"
            @click="foo(item)"
          />
        </data-table-actions>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/collections/DataTable/DataTable';
import { REPORT_DATE_HEADERS } from '@/views/Report/constants';
import ActivityTimeAvg from '@/components/collections/ActivityBar/ActivityTimeAvg';
import DataTableActionItem from '@/components/collections/DataTableActions/DataTableActionItem';
import DataTableActions from '@/components/collections/DataTableActions/DataTableActions';
import Action from '@/components/ui/Icons/Action';

export default {
  name: 'DataTableList',
  components: {
    Action,
    DataTableActions,
    DataTableActionItem,
    ActivityTimeAvg,
    DataTable,
  },
  data() {
    return {
      reports: [
        {
          id: '1',
          project: 'Test Project',
          activity: '80',
          duration: '00:55:15',
          timeSpan: '11:00:35 - 12:55:50',
          manual: '100%',
          reasonOfDelete: 'That is not my time. I returned him.',
        },
        {
          id: '2',
          project: 'Test Project',
          activity: '0',
          duration: '00:55:15',
          timeSpan: '11:00:35 - 12:55:50',
          manual: '100%',
          reasonOfDelete: 'That is not my time. I returned him.',
        },
        {
          id: '3',
          project: 'Test Project',
          activity: '80',
          duration: '00:55:15',
          timeSpan: '11:00:35 - 12:55:50',
          manual: '100%',
          reasonOfDelete: 'That is not my time. I returned him.',
        },
      ],
      headers: REPORT_DATE_HEADERS,
    };
  },
  methods: {
    foo() {
      console.log(1);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .activity {
    margin-bottom: $spacing-6;
  }
</style>
